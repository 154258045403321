import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
import Links from 'components/links';
import { LinkLabel } from 'utils/constants';
import { postMap, slugify } from 'utils/post-map';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`We're Not Selling Saddles Here`}</h1>
    <p><strong parentName="p">{`All Access Pass - October 8, 2020`}</strong></p>
    <p><strong parentName="p">{`Yesterday we felt stuck. We hit a plateau. We had a cup of coffee with the
plateau...and then handled it like a master.`}</strong></p>
    <p>{`One of the questions I ask myself is “what would suli do?”`}</p>
    <p>{`Suli is one of my best friends, and probably the best entrepreneur I know.`}</p>
    <p>{`So when I get stuck, I think about how he’d approach it. The answer is clear. He
wouldn’t have doubts or switch strategies. He’d crank up the intensity 10x until
the current strategy starts to work!`}</p>
    <p><strong parentName="p">{`Cranking up the intensity means:`}</strong></p>
    <ul>
      <li parentName="ul">{`Do the same thing, but do a better job of it.`}</li>
      <li parentName="ul">{`Do it to the point where if it still doesn’t work, you’ll know it wasn’t
because you half assed it. You will `}<strong parentName="li">{`know`}</strong>{` the strategy doesn’t work.`}</li>
    </ul>
    <p><strong parentName="p">{`So this morning we talked about it, and Ben started to crank up the
intensity.`}</strong></p>
    <p><strong parentName="p">{`Step 1: Stop Selling Saddles`}</strong></p>
    <p>{`Have you read the blog post:
“`}<a parentName="p" {...{
        "href": "https://medium.com/@stewart/we-dont-sell-saddles-here-4c59524d650d"
      }}>{`We don’t sell saddles here`}</a>{`”?`}</p>
    <p><strong parentName="p">{`If not, stop reading this. Click the link. Read that shit and come back. It’s
a must-read.`}</strong></p>
    <p>{`This is our problem. We’re selling saddles to people who don’t even know they
want to go horseback riding.`}</p>
    <p>{`Sometimes in new markets, you need to first sell the dream of horseback riding,
instead of selling your saddle.`}</p>
    <p><strong parentName="p">{`In our case:`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Saddle =`}</strong>{` our newsletter/ebook/course about buying businesses`}</li>
      <li parentName="ul"><strong parentName="li">{`Horseback Riding =`}</strong>{` being the person who owns a portfolio of cashflowing
profitable businesses rather than a job or struggling to create a startup from
scratch.`}</li>
    </ul>
    <p><strong parentName="p">{`So, to sell horseback riding, I’m going to tell you about some badass cowboys
who ride horses:`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Andrew Wilkinson -`}</strong>{` has built a an empire of over 25 businesses with
combined revenues > `}{`$`}{`100M+ space of buying internet companies`}</li>
      <li parentName="ul"><strong parentName="li">{`Chamath -`}</strong>{` he’s buying big stakes in big companies like Virgin Galactic,
Clover Health, Opendoor etc.. through his SPACs.`}</li>
      <li parentName="ul"><strong parentName="li">{`Xavier Helgesen & Sieva Kozinsky -`}</strong>{` quietly building an empire at Enduring
Ventures`}</li>
      <li parentName="ul"><strong parentName="li">{`Brent Beshore -`}</strong>{` rolls up a shit ton of brick and mortar businesses under
his brand “Permanent Equity”.`}</li>
    </ul>
    <p>{`So Ben re-did our landing page and ads to promote the path these guys are taking
to build their empires. We’ll tell their stories first… and then we’ll tell you
how you can do it too.`}</p>
    <p><strong parentName="p">{`The New Landing Page:
`}<a parentName="strong" {...{
          "href": "https://buydontbuild.com/"
        }}>{`Click here if you wanna check it out live`}</a></strong></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1125px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "216.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAArABQDASIAAhEBAxEB/8QAGQAAAwEBAQAAAAAAAAAAAAAAAAECAwQF/8QAGAEAAwEBAAAAAAAAAAAAAAAAAAECAwT/2gAMAwEAAhADEAAAAdX5t9OfaecCtqrWQxmt89Z0hFz/AP/EAB0QAAICAgMBAAAAAAAAAAAAAAABAhADFBITIUH/2gAIAQEAAQUC25G3I25H3ykjjSPKWaCO/ESkpsdf/8QAFxEAAwEAAAAAAAAAAAAAAAAAABARAf/aAAgBAwEBPwGl11f/xAAYEQADAQEAAAAAAAAAAAAAAAAAAQISEP/aAAgBAgEBPwHCMT1yI//EACIQAAECAwkAAAAAAAAAAAAAAAEAEQIDMhASIDAxNFFhof/aAAgBAQAGPwKkLQKgWHIYyH7W39Tww3Bxg//EACEQAAICAQIHAAAAAAAAAAAAAAABETFBECFRYXGBkdHh/9oACAEBAAE/IVwuebIM/ki9rFEJrJ8hkmTqGtyEbwdg7F6mVcrIlloJQrKaf//aAAwDAQACAAMAAAAQfzf8oz//xAAaEQACAgMAAAAAAAAAAAAAAAAAASExEBFh/9oACAEDAQE/EE2rO2VChyf/xAAaEQACAgMAAAAAAAAAAAAAAAAAARExECFh/9oACAECAQE/EE90cMQbLYkKD//EACIQAQACAgECBwAAAAAAAAAAAAEAESExQVFhEHGBkcHR8f/aAAgBAQABPxAC7Dd1RNfk7HxC7WTMjbNhw7RtcA7tzeCTEelRpsgcpNQRDmPELHUmP5iFIUROw6VV3RuPaX1FaEKu36zVMOXh/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Buy Don't Build Landing Page 1",
          "title": "Buy Don't Build Landing Page 1",
          "src": "/static/9d7287dd6378b8696452db86039ab68b/551ac/buy-dont-build-landing-page-1.jpg",
          "srcSet": ["/static/9d7287dd6378b8696452db86039ab68b/a6407/buy-dont-build-landing-page-1.jpg 400w", "/static/9d7287dd6378b8696452db86039ab68b/a24e6/buy-dont-build-landing-page-1.jpg 800w", "/static/9d7287dd6378b8696452db86039ab68b/551ac/buy-dont-build-landing-page-1.jpg 1125w"],
          "sizes": "(max-width: 1125px) 100vw, 1125px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1125px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "216.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAArABQDASIAAhEBAxEB/8QAGQAAAwEBAQAAAAAAAAAAAAAAAAIFAQME/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAbWridBxZ6YpRECa9TjQekj/xAAeEAACAgICAwAAAAAAAAAAAAABAgMRABIQISIxMv/aAAgBAQABBQKsrNRh98O72ZX2Q9TL5zgVGKSbctIWaOH4Y0EYk5//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AV//xAAWEQADAAAAAAAAAAAAAAAAAAAQICH/2gAIAQIBAT8BSD//xAAiEAABBAECBwAAAAAAAAAAAAABAAIRMUESUQMQICEjYXH/2gAIAQEABj8CrqMGEwbozuj5IBxFIanUMNQ76vac0MN2hPDdJGFUfUef/8QAHxABAQEAAQQDAQAAAAAAAAAAAREAITFhgaEQQVHh/9oACAEBAAE/IYqCxz+9DHlx60/T1/PgFmF5lNVGBLmaLMkyu4TLmfgO+g+aeWaBBRHXRec4Fm5UIXoI5lGjVxv/2gAMAwEAAgADAAAAEM/uwIMP/8QAFhEBAQEAAAAAAAAAAAAAAAAAARAR/9oACAEDAQE/EMmGQUn/xAAZEQACAwEAAAAAAAAAAAAAAAAAARExQSH/2gAIAQIBAT8Qklm2OxtsH1n/xAAeEAEBAAMAAgMBAAAAAAAAAAABEQAhMUFRcYGh0f/aAAgBAQABPxCFgvb/AHOBI9qYWS733E6O3VX8wtKguQAAcwZM2RAGBAuEUkwBVTprxhw7QFCzXrCHjAD8BVHVfOe/CNHweqGI52GY76X6mLfhTTcZo5lB03YVJfjeDYJpAEDZiQxDWD6yOoYqZ//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Buy Don't Build Landing Page 2",
          "title": "Buy Don't Build Landing Page 2",
          "src": "/static/3529f2b3b13416bf0e0370c8c72c9af7/551ac/buy-dont-build-landing-page-2.jpg",
          "srcSet": ["/static/3529f2b3b13416bf0e0370c8c72c9af7/a6407/buy-dont-build-landing-page-2.jpg 400w", "/static/3529f2b3b13416bf0e0370c8c72c9af7/a24e6/buy-dont-build-landing-page-2.jpg 800w", "/static/3529f2b3b13416bf0e0370c8c72c9af7/551ac/buy-dont-build-landing-page-2.jpg 1125w"],
          "sizes": "(max-width: 1125px) 100vw, 1125px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><strong parentName="p">{`The New Ads:`}</strong></p>
    <p><strong parentName="p">{`We setup a new ad campaign with
$150/day budget to see if we can get our list building faster. I wanna get to ~$1
per subscriber, and build a 1k person list for `}{`$`}{`1k, as soon as I can.`}</strong></p>
    <p><strong parentName="p">{`Before:`}</strong></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "132%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAaABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAQDBQL/xAAXAQEBAQEAAAAAAAAAAAAAAAAAAQME/9oADAMBAAIQAxAAAAHiL9+jLkrlVeclVpCf/8QAGxAAAgMAAwAAAAAAAAAAAAAAAAIBERMDICP/2gAIAQEAAQUCzYzYzYqxrlRm4zzrZI6f/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAEREgL/2gAIAQMBAT8B0zbF1Cs//8QAGhEAAgIDAAAAAAAAAAAAAAAAAAECExESQf/aAAgBAgEBPwGtFSHHPTRH/8QAGhAAAQUBAAAAAAAAAAAAAAAAEAIDESAhIv/aAAgBAQAGPwKkDEjlvKf/xAAfEAACAQQCAwAAAAAAAAAAAAAAAREhMWGhQVEQcZH/2gAIAQEAAT8hwbMWzFsTWDIw65ovXhlcvaoWW0/pZQCCCD//2gAMAwEAAgADAAAAEOfMs//EABYRAQEBAAAAAAAAAAAAAAAAAAEQEf/aAAgBAwEBPxAaGBMGX//EABcRAQEBAQAAAAAAAAAAAAAAAAEAEHH/2gAIAQIBAT8QTS7yoRGP/8QAHRAAAgICAwEAAAAAAAAAAAAAAREAIVFhMUGRcf/aAAgBAQABPxDX8TT8TT8QwQj+wuzC4EKAqKn3OOxDwMPIkF6kWhY6MNRg4BRMTAiYETAn/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Ad Design Before",
          "title": "Ad Design Before",
          "src": "/static/72dd8aadc863646201b4768f026002a6/03ffe/ad-design-before.jpg",
          "srcSet": ["/static/72dd8aadc863646201b4768f026002a6/a6407/ad-design-before.jpg 400w", "/static/72dd8aadc863646201b4768f026002a6/a24e6/ad-design-before.jpg 800w", "/static/72dd8aadc863646201b4768f026002a6/03ffe/ad-design-before.jpg 1200w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><strong parentName="p">{`After:`}</strong></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "100%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAIDBAEF/8QAFgEBAQEAAAAAAAAAAAAAAAAABAMC/9oADAMBAAIQAxAAAAHTyysF5q1M+XAVLcHn/8QAHRAAAQMFAQAAAAAAAAAAAAAAAQADEgQQERMhMf/aAAgBAQABBQI5XbzBM1VE7HvG+t//xAAZEQADAAMAAAAAAAAAAAAAAAAAAQIREzH/2gAIAQMBAT8BbwbYK6M//8QAHREAAQIHAAAAAAAAAAAAAAAAAAECAxIUITEyUf/aAAgBAgEBPwFjJksUsXgzVBMH/8QAGhAAAgMBAQAAAAAAAAAAAAAAABECEEEycv/aAAgBAQAGPwLTb6dLEQ8kT//EAB0QAAIDAAIDAAAAAAAAAAAAAAABESExEFFBYdH/2gAIAQEAAT8h6IEebCz7wugKPRD1jhgfGqOhmw9g/9oADAMBAAIAAwAAABAv7wD/xAAZEQADAAMAAAAAAAAAAAAAAAAAASERMUH/2gAIAQMBAT8QU1MXRrNmf//EABsRAQEAAQUAAAAAAAAAAAAAAAEAESExQbHR/9oACAECAQE/EEbkQ+vc9iGK2r//xAAeEAEAAwACAgMAAAAAAAAAAAABABEhMUFRcWGhsf/aAAgBAQABPxCy9V9IkAdZ4oJflf6jxrR2+JigL42/Weo66fllvkCgtbHt02YbeX6jhqja9z//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Ad Design After 1",
          "title": "Ad Design After 1",
          "src": "/static/77f3a887be2382b65c5cf172c82ca59e/03ffe/ad-design-after-1.jpg",
          "srcSet": ["/static/77f3a887be2382b65c5cf172c82ca59e/a6407/ad-design-after-1.jpg 400w", "/static/77f3a887be2382b65c5cf172c82ca59e/a24e6/ad-design-after-1.jpg 800w", "/static/77f3a887be2382b65c5cf172c82ca59e/03ffe/ad-design-after-1.jpg 1200w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1222px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "204.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAApABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAECAwT/xAAWAQEBAQAAAAAAAAAAAAAAAAADBAH/2gAMAwEAAhADEAAAAeOrDtwDq9SzJaDXHH0tOKNP/8QAHRAAAQQCAwAAAAAAAAAAAAAAAQACEhMDERAgIf/aAAgBAQABBQIDZrdFM0Hz8VeNV41XjWgXRHDlE9f/xAAdEQACAQQDAAAAAAAAAAAAAAABAgMAEBESITFR/9oACAEDAQE/AZJCrYFmfU91z7TRhjk2/8QAGhEAAgMBAQAAAAAAAAAAAAAAAQIAEBESA//aAAgBAgEBPwFV0UvTTsxfQqMFf//EACEQAAEDAwQDAAAAAAAAAAAAAAEAETICEJESICExQWFx/9oACAEBAAY/AmC1OGsH6VVPg2mcKZwpnCDhVuOhbgsVL772/wD/xAAfEAEAAQMEAwAAAAAAAAAAAAABEQAQISAxYXFBUaH/2gAIAQEAAT8hUkB5rJnDf3Zod1TCk5Z8mkTphCSWKDgDE4PlpR1DWUZzycNP/9oADAMBAAIAAwAAABDH2EIHH//EABsRAAEEAwAAAAAAAAAAAAAAAAEQIaHRADFx/9oACAEDAQE/EB3QyMhKKzpFKw//xAAZEQEAAgMAAAAAAAAAAAAAAAABEBEAIWH/2gAIAQIBAT8Q2kULAzkSQ//EACIQAQEAAQIFBQAAAAAAAAAAAAERADHRECFBUWEgcYGR4f/aAAgBAQABPxBMKvPRpcIJHACB7RPJwU6Ba8RwbF4GRKa9+RwT1P475LZ74fgt8LUA+2ISwWEVlc2++BCGLpujHRxSIMFoHRMGlPR//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Ad Design After 2",
          "title": "Ad Design After 2",
          "src": "/static/e2318a3f0e87ac2a6461292f8abb8048/aab54/ad-design-after-2.jpg",
          "srcSet": ["/static/e2318a3f0e87ac2a6461292f8abb8048/a6407/ad-design-after-2.jpg 400w", "/static/e2318a3f0e87ac2a6461292f8abb8048/a24e6/ad-design-after-2.jpg 800w", "/static/e2318a3f0e87ac2a6461292f8abb8048/aab54/ad-design-after-2.jpg 1222w"],
          "sizes": "(max-width: 1222px) 100vw, 1222px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1187px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "210.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAqABQDASIAAhEBAxEB/8QAGQAAAwEBAQAAAAAAAAAAAAAAAAEDBAIF/8QAFwEBAQEBAAAAAAAAAAAAAAAAAgMBBP/aAAwDAQACEAMQAAAB9GfMGd5yLGs8uaG8zBmpXWFgTj//xAAeEAACAgICAwAAAAAAAAAAAAABEQACEiIDECAhMv/aAAgBAQABBQItv3Oa2AreplfnWazWIEjHpoh+P//EABsRAQACAgMAAAAAAAAAAAAAAAIAARASAxET/9oACAEDAQE/Aa6wW7O091DyWTrj/8QAGREAAgMBAAAAAAAAAAAAAAAAAQIAEBET/9oACAECAQE/ATtB3M7NA+V//8QAIRAAAgEEAAcAAAAAAAAAAAAAAAERAhAhMhIgIlFhcaH/2gAIAQEABj8CwyOL5ZVRMM7dIvRsbs2MlUqIsmPCz55f/8QAIRABAAIBAwQDAAAAAAAAAAAAAQARITFBURBhkeFxgaH/2gAIAQEAAT8hDwT6g3BvxYaFtzLXaHxHKRzUU1c/Ojff4lehBPpEzPPEuQqrvrcCiiNEzTpfaK5TENmXSjiUcdP/2gAMAwEAAgADAAAAEDzlvhAf/8QAHREBAAEDBQAAAAAAAAAAAAAAAQAQETFRYaHR8P/aAAgBAwEBPxAIb0TALHtZtHPcWDDT/8QAGBEBAQEBAQAAAAAAAAAAAAAAAREAEDH/2gAIAQIBAT8QkkPc4SnBhA5//8QAIRABAAICAQQDAQAAAAAAAAAAAQARITFBUWGBwRBxkfD/2gAIAQEAAT8QwwXAN8wubAo2KN06al8Qqyhuchp53IjmkM1BGr1cFF/FECLWvRQFy3lBKu/blVHR2eBfUFvoiNAqPPWEWg6tykheWg5HuLFirApuntuCORuWbD4nafnx/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Ad Design After 3",
          "title": "Ad Design After 3",
          "src": "/static/f56530d2100f73024996e3ec4a46a798/3ce2d/ad-design-after-3.jpg",
          "srcSet": ["/static/f56530d2100f73024996e3ec4a46a798/a6407/ad-design-after-3.jpg 400w", "/static/f56530d2100f73024996e3ec4a46a798/a24e6/ad-design-after-3.jpg 800w", "/static/f56530d2100f73024996e3ec4a46a798/3ce2d/ad-design-after-3.jpg 1187w"],
          "sizes": "(max-width: 1187px) 100vw, 1187px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><strong parentName="p"><em parentName="strong">{`Today has been all about taking action - and now we wait for Facebook to turn
all of the ads on and see if any of these changes make a difference`}</em></strong></p>
    <p>{`until tomorrow,`}<br parentName="p"></br>{`
`}{`shaan`}</p>
    <Links links={[{
      title: LinkLabel.Web,
      links: [{
        url: 'https://medium.com/@stewart/we-dont-sell-saddles-here-4c59524d650d',
        label: "We Don't Sell Saddles Here"
      }, {
        url: 'https://buydontbuild.com/',
        label: 'The New Landing Page'
      }]
    }]} mdxType="Links" />
    <PostFooter prevButton={{
      text: postMap.picking_the_mission_for_the_week.label,
      slug: slugify(postMap.picking_the_mission_for_the_week.slug)
    }} nextButton={{
      text: postMap.debating_isn_t_just_for_presidents.label,
      slug: slugify(postMap.debating_isn_t_just_for_presidents.slug)
    }} mdxType="PostFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      